import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ButtonLink } from '@dtx-company/inter-app/src/utils/links'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { DefaultManager, ManagerProps } from './components/Manager/Manager'
import { FileUpload } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/FileUpload'
import { FileUploadInput } from '../util/SettingsUtil'
import { FlowpageLinkCard } from '../flowpage/FlowpageLinkCard'
import { FlowpageProps } from './types/FlowpageProps'
import { FormFields } from './types'
import { FormProps, StyledForm } from './components/Layout'
import { FormTitle } from './components/Title'
import { ImageDisplayToggle } from './Destination/ImageDisplayToggle'
import { ImageEdit } from './components/ImageEdit'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkStyleGated } from './components/LinkStyle/LinkStyleGated'
import { MaskType } from '@dtx-company/shared-components'
import { PdfUploadWrapper } from '../profile/LinkForm/utils'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SettingsAndSaveLink } from './components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { TITLE_MAX, TITLE_MAX_ERROR } from './validation'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { TitleInput } from './components/Inputs'
import { dataURItoBlob } from '../../utils/imageEditor'
import { fileUpload } from '../../assets/logos'
import { fileUploadColor } from '@app/code/src/assets'
import { getValidatedActionData } from './typeUtils'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from './components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview } from './utils'
import { useSubmitWidget } from './submitUtils'
import { v4 as uuid } from 'uuid'

interface PDFFormFields {
  pdf: File | null
  title: string
  image: string | null
  displayType: string
}
const { TITLE, IMAGE, DISPLAY_TYPE } = FormFields
enum PDFField {
  PDF = 'pdf'
}

export const Form: FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  const actionData = getValidatedActionData<'pdf'>(curr?.actionData, 'link')

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors
  } = useForm<PDFFormFields>({
    defaultValues: {
      title: curr?.title || '',
      pdf: null,
      image: curr?.thumbNailImgUrl || '',
      displayType: curr?.displayType || 'default'
    },
    mode: 'onBlur'
  })
  const id = curr ? curr.id : uuid()
  const submitWidget = useSubmitWidget()
  const onSubmit = async ({ title, pdf, image, displayType }: PDFFormFields): Promise<void> => {
    setSubmitting(true)

    const newActionData = pdf ? { link: URL.createObjectURL(pdf) } : curr?.actionData
    await submitWidget({
      image: image || '',
      curr,
      linkTheme,
      actionData: newActionData || {},
      widgetType: 'pdf',
      fields: {
        id,
        description: '',
        title,
        order,
        displayType: displayType || 'default'
      }
    })

    setSubmitting(false)
    handleClose()
  }

  useEffect(() => {
    register(DISPLAY_TYPE)
    register(TITLE, {
      required: true,
      maxLength: {
        value: TITLE_MAX,
        message: TITLE_MAX_ERROR
      }
    })
    register(IMAGE)
    register(PDFField.PDF, {
      required: actionData?.link ? false : true
    })
  }, [actionData?.link, register])
  const watchAll = watch()

  const disabled = (!curr && !watchAll?.pdf) || watchAll?.title?.length === 0
  const edit = Boolean(curr)
  const previewLink = useMemo(
    () => ({
      provider: LinkProvider.WIDGET,
      type: 'pdf',
      embed: false,
      __typename: 'Link' as const,
      actionData: {},
      title: watchAll.title,
      thumbNailImgUrl: watchAll.image,
      id,
      order,
      linkTheme,
      active: true,
      description: '',
      displayType: watchAll.displayType
    }),
    [id, linkTheme, order, watchAll.image, watchAll.title, watchAll.displayType]
  )

  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )

  const MAX_FILE_SIZE = 20000000
  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        title={<FormTitle icon={<FileUpload />} title={`${edit ? 'Edit this' : 'Add a'} File`} />}
      >
        <TitleInput
          value={watchAll.title}
          errors={errors}
          onChange={e => setValue(TITLE, e.target.value)}
        />
        <PdfUploadWrapper data-testid="PDFUploadWrapper">
          <Box alignItems="center" position="relative">
            <FileUploadInput
              id={`${id}-pdf-input`}
              accept="application/pdf,video/mp4,audio/*"
              handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                const files = e?.target?.files
                if (files && files?.length > 0) {
                  if (files[0].size > MAX_FILE_SIZE) {
                    setError(PDFField.PDF, {
                      type: 'error',
                      message: `File is too large. Please upload a file less than ${
                        MAX_FILE_SIZE / 1000000
                      }MB.`
                    })
                  } else {
                    setValue(PDFField.PDF, files[0])
                    clearErrors(PDFField.PDF)
                  }
                } else {
                  setValue(PDFField.PDF, null)
                  clearErrors(PDFField.PDF)
                }
              }}
            >
              <SecondaryButton
                type="button"
                label={`${actionData?.link ? 'Replace' : 'Upload'} file`}
              />
            </FileUploadInput>
            <Spacer ml="8px" />
            <Text mb="2px">{`Max size: ${MAX_FILE_SIZE / 1000000}MB`}</Text>
          </Box>
        </PdfUploadWrapper>
        {actionData?.link && (
          <>
            <ButtonLink
              target="_blank"
              sizeVariant="medium"
              rel="noopener noreferrer"
              colorVariant="secondary"
              href={actionData?.link}
              label="View File"
              backgroundColor="transparent"
            />
          </>
        )}
        {watchAll.pdf && (
          <Text ml="4px" mt="12px" lineHeight="22px">
            <a
              href={watchAll.pdf?.name ? URL.createObjectURL(watchAll.pdf) : actionData?.link}
              target="blank"
            >
              {watchAll.pdf?.name}
            </a>
          </Text>
        )}
        <Spacer ml="16px" />
        <ImageDisplayToggle
          displayType={watchAll.displayType}
          setValue={setValue}
          fetching={false}
        />
        <ImageEdit
          replaceButtonCTA="Replace Icon"
          deleteButtonCTA="Delete Icon"
          imageUrl={watchAll.image || fileUploadColor}
          setValue={setValue}
          defaultImage={fileUploadColor}
          id={id}
          active={curr ? curr.active : true}
          maskType={watchAll.displayType === 'default' ? MaskType.SQUARE : MaskType.FEATURED}
        />
        {errors?.pdf && (
          <Text variant="body/small" color="status.errorDark">
            {errors?.pdf?.message || 'File Link is Required'}
          </Text>
        )}

        <Spacer mb="16px" />
        <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkThemeValue} />

        <Spacer mt="32px" />
        <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview = false,
  editLinkMode = false,
  isLockedTemplateLink
}: FlowpageProps) => {
  const ad = getValidatedActionData<'pdf'>(link.actionData, 'link')

  return (
    <FlowpageLinkCard
      link={{
        ...link,
        actionData: {
          link: ad?.link.startsWith('data:')
            ? URL.createObjectURL(dataURItoBlob(ad.link))
            : ad?.link || ''
        }
      }}
      isLockedTemplateLink={isLockedTemplateLink}
      widgetId="pdf"
      preview={preview}
      editLinkMode={editLinkMode}
      iconSrc={link.thumbNailImgUrl || fileUploadColor}
    />
  )
}

export const Manager: FC<ManagerProps> = ({ link, editWidget, key, handle }: ManagerProps) => {
  return <DefaultManager iconUrl={fileUpload} handle={handle} editWidget={editWidget} link={link} />
}
